/* All the global css which apply whole App is here */
html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif !important;
  font-style: normal;
  font-size: 62.5%;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
input {
  font-size: 1.6rem !important;
  padding: 2px !important;
}
label {
  font-size: 1.6rem !important;
}
