/* .modal {
  width: 1140px !important;
  max-height: 700px;
}

.ant-modal-body {
  padding: 0 !important;
}

.video {
  width: 100% !important;
  height: 700px;
}

.video-wrapper {
  width: 100%;
  
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
}

.video-wrapper:hover .controls {
  transform: translateY(0%);
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  bottom: 150px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  padding: 14px;
  width: 100%;
  flex-wrap: wrap;
  border-radius: 10px;
  transform: translateY(150%);
  transition: all 0.3s ease-in-out;
}

.actions button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.actions button i {
  background-color: none;
  color: white;
  font-size: 3rem;
}

input[type='range'] {
  -webkit-appearance: none !important;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  height: 4px;
  width: 350px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  cursor: pointer;
  height: 6px;
}

input[type='range']::-moz-range-progress {
  background: white;
}



.mute_btn {
  background: none;
  border: none;
  outline: none;
  cursor: pointer !important;
  height: 100%;
  display: flex;
  align-items: center;
}

.div_main {
  background: linear-gradient(
    180deg,
    #000000 0%,
    rgba(0, 0, 0, 0) 100%
  ) !important;
  position: absolute;
  width: 100%;
  padding-top: 2%;
  padding-left: 2%;
}

.div_2 {
  top: 5%;
  left: 5%;
  z-index: 9999;
}

.head_1 {
  margin: 0;
  font-size: 2rem;
  line-height: 28px;
  color: white;
}

.timer_head {
  margin: 0;
  color: white;
  font-size: 3.2rem;
  line-height: 46px;
  font-weight: 800;
}

.rap_head {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.playpausebuttons {
  display: flex;
  gap: 50px;
}

.text_prev_next {
  margin: 0;
  color: white;
  font-size: 2rem;
  line-height: 28px;
  font-weight: 600;
}

.play_pause_btns {
  background: white;
  padding: 10px;
  border-radius: 50%;
}

@media (max-width: 800px) {
  .modal {
    width: 700px !important;
    max-height: 700px;
  }

  .playpausebuttons {
    gap: 25px;
  }
  .text_prev_next {
    font-size: 1.6rem;
    line-height: 24px;
  }
}

@media (max-width: 468px) {
  .text_prev_next {
    font-size: 1rem;
    line-height: 12px;
  }
  
  .head_1 {
    font-size: 1.5rem;
    line-height: 18px;
  }

  .timer_head {
    font-size: 1.6rem;
    line-height: 20px;
  }

  .playpausebuttons {
    gap: 10px;
  }
} */

.modal {
  width: 1140px !important;
  max-height: 700px;
}

.ant-modal-body {
  padding: 0 !important;
}

/* video {
  width: 1140px;
  height: 720px;
} */

.video {
  width: 100% !important;
  height: 700px;
}

.video-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
}

.video-wrapper:hover .controls {
  transform: translateY(0%);
}

.controls {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 195px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  padding: 30px;
  width: 100%;
  flex-wrap: wrap;
  border-radius: 10px;
  transform: translateY(150%);
  transition: all 0.3s ease-in-out;
}

.actions button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.actions button i {
  background-color: none;
  color: white;
  font-size: 3rem;
}

input[type='range'] {
  -webkit-appearance: none !important;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  height: 4px;
  width: 350px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  cursor: pointer;
  height: 6px;
}

input[type='range']::-moz-range-progress {
  background: white;
}

.mute_btn {
  background: none;
  border: none;
  outline: none;
  cursor: pointer !important;
  height: 100%;
  display: flex;
  align-items: center;
}

.div_main {
  background: linear-gradient(
    180deg,
    #000000 0%,
    rgba(0, 0, 0, 0) 100%
  ) !important;
  position: absolute;
  width: 100%;
  padding-top: 30px;
  padding-left: 30px;
}

.div_2 {
  top: 5%;
  left: 5%;
  z-index: 9999;
}

.head_1 {
  margin: 0;
  font-size: 2rem;
  line-height: 28px;
  color: white;
}

.timer_head {
  margin: 0;
  color: white;
  font-size: 3.2rem;
  line-height: 46px;
  font-weight: 800;
}

.rap_head {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.playpausebuttons {
  display: flex;
  align-items: center;
  gap: 50px;
  width: 100%;
}

.text_prev_next {
  margin: 0;
  color: white;
  font-size: 2rem;
  line-height: 28px;
  font-weight: 600;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.smalltext {
  margin: 0;
  color: white;
  font-size: 2rem;
  line-height: 28px;
  font-weight: 600;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  display: none;
  -webkit-box-orient: vertical;
}

.next_prev_title {
  margin: 0;
  color: white;
  font-size: 2.4rem;
  line-height: 34px;
  width: 100%;
  max-width: 83%;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.next_prev_timer {
  margin: 0;
  color: white;
  font-size: 2.4rem;
  line-height: 34px;
  font-weight: 800;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.play_pause_btns {
  background: white;
  padding: 10px;
  border-radius: 50%;
}

.mainbtnplaypause {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  outline: none;
  border: 0;
}

.righttopbtn {
  display: flex;
  gap: 10px;
  cursor: pointer;
  align-items: center;
}

.prevtopbtn {
  display: flex;
  gap: 10px;
  background: none;
  border: none;
  align-items: center;
  justify-content: right;
  width: 100%;
  padding: 0px;
}

@media (max-width: 837px) {
  .modal {
    width: 700px !important;
    max-height: 700px;
  }

  .controls {
    bottom: 182px;
  }

  .playpausebuttons {
    gap: 25px;
  }
  .text_prev_next {
    display: none;
  }

  .smalltext {
    font-size: 1.6rem;
    line-height: 24px;
    display: block;
  }
  .next_prev_timer {
    font-size: 2rem;
    line-height: 30px;
  }
  .next_prev_title {
    display: none;
  }
}

@media (max-width: 550px) {
  .controls {
    bottom: 126px;
    padding: 16px;
  }
  .smalltext {
    font-size: 1.2rem;
    line-height: 12px;
  }

  .next_prev_timer {
    font-size: 1.2rem;
    line-height: 17px;
  }

  .head_1 {
    font-size: 1.5rem;
    line-height: 18px;
  }

  .timer_head {
    font-size: 1.6rem;
    line-height: 20px;
  }

  .playpausebuttons {
    gap: 10px;
  }
}

@media (max-width: 356px) {
  .controls {
    bottom: 128px;
  }
  .playpausebuttons > div > div {
    gap: 5px !important;
  }
}

@media (max-width: 392px) {
  .playpausebuttons {
    gap: 5px;
  }
  .controls {
    padding: 10px;
    bottom: 90px;
  }
  .mute_btn > svg {
    width: 16px;
    height: 16px;
  }
  .righttopbtn > svg {
    height: 16px;
    width: 16px;
  }
  .prevtopbtn > svg {
    height: 16px;
    width: 16px;
  }
  .mainbtnplaypause > svg {
    height: 20px;
    width: 20px;
  }
}
