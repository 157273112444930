.header_comp {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white !important;
  box-shadow: 1px 0px 50px rgba(205, 202, 194, 0.25);
  padding: 20px 80px;
  position: sticky;
  top: 0;
  z-index: 1000;
}
.logo_wrapper {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.logo_wrapper .search {
  width: 50%;
  max-width: 300px;
  border-radius: 15px !important;
  margin-left: 30px;
}
.user_icon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: #e1e0de;
  padding: 8px;
  cursor: pointer;
  color: white !important;
}

.content {
  padding: 30px 85px;
  background-color: #f7f7f7;
  overflow-x: hidden;
  height: calc(100vh - 88px);
}

.search_icon {
  display: none !important;
  margin: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e2e1dc;
  border-radius: 50%;
}

.search_svg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px;
}

.search_btn_input {
  display: none !important;
  border-radius: 8px;
}

.search_logo {
  display: none !important;
}

@media only screen and (max-width: 450px) {
  .logo_wrapper .search {
    display: none;
  }
}

@media (max-width: 768px) {
  .header_comp {
    padding: 12px 20px;
  }

  .content {
    padding: 16px 20px;
  }
}

@media (max-width: 450px) {
  .search_btn_input {
    display: block !important;
  }

  .search_icon {
    display: block !important;
  }

  .search_logo {
    display: block !important;
  }

  .main_logo {
    display: none !important;
  }
}
