.info {
  margin: 30px 0;
}
.info_header_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}
.info_header_img {
  width: 44%;
  height: 320px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  object-fit: contain;
}
.info_content {
  width: 50%;
}
.info_content_title {
  font-weight: 600;
}
.info_content_icon_text {
  font-size: 1.6rem !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  margin: 15px 0 !important;
}
.btn_wrapper {
  text-align: end;
}
.btn_wrapper button {
  border-radius: 5px;
}
.info_footer {
  margin-top: 30px;
}
.info_footer_title {
  font-weight: 600;
  font-size: 1.8rem !important;
}
.info_footer p {
  font-size: 1.6rem !important;
}

.btn_start > span {
  font-size: 1.6rem;
  line-height: 26px;
}

@media only screen and (max-width: 600px) {
  .info_header_wrapper {
    flex-direction: column;
  }
  .info_header_img {
    width: 90%;
    margin: auto;
  }
  .info_content {
    width: 100%;
  }
  .info_content_title {
    text-align: center;
  }
}

.header_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}
.header_icon {
  width: 40px;
  height: 40px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: white;
  box-shadow: 1px 0px 50px rgba(205, 202, 194, 0.25);
  border-radius: 50% !important;
  cursor: pointer;
  color: black;
  padding: 0;
}
.header_title {
  font-weight: 600;
}
