/* /All the overwrite ant-design css will be here / */
.ant-modal-content {
  background-color: none !important;
  background: none !important;
  padding: 0 !important;
}

.ant-btn-link {
  padding: 0 !important;
}

.ant-btn-link > span {
  font-size: 1.8rem;
  line-height: 26px;
}

.ant-tooltip-open {
  color: black;
}

.ant-modal .ant-modal-close {
  width: 32px;
  top: 30px;
  right: 35px;
  height: 32px;
}

.ant-modal .ant-modal-close:hover {
  background: none;
}

@media (max-width: 837px) {
  .ant-modal-close-x > svg {
    width: 24px;
    height: 24px;
  }
}
