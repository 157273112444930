.video {
  width: 95%;
  /* max-width: 480px;
  min-width: 295px !important; */
}

.video_wrapper {
  color: black;
  width: 100% !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}

.video_img {
  border-radius: 8px;
  object-fit: cover;
  object-position: center 30%;
  /* height: 100%; */
  height: 220px;
  /*  min-width: 280px; */
  width: 100% !important;
  /* max-width: 380px;
  min-height: 220px; */
}

.video_title {
  text-overflow: ellipsis;
  font-size: 1.8rem;
  overflow: hidden;
  margin-top: 10px !important;
  display: -webkit-box;
  max-width: 90%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.video_footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  margin-top: 10px;
}

.video_footer_info_text {
  font-size: 1.6rem !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  color: #66645e;
  max-width: 30%;
  position: relative;
}

.video_footer_info_span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  /* max-width: 40% !important; */
  display: block;
}

@media (max-width: 1440px) {
  .video_img {
    width: 100% !important;
    /* min-height: 180px;
    max-width: 270px;
    max-height: 180px;
    min-width: 250px; */
  }
}

@media (max-width: 575px) {
  .video_footer_info_text {
    max-width: 60% !important;
  }
}

@media (max-width: 500px) {
  .see_all_item {
    margin: auto;
  }
}
