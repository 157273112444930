/* model
.modal {
  width: 1140px !important;
  max-height: 700px;
}

.width {
  width: 100%;
}

main-content
/* .main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
}

.main_without_video {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
}

.leftBox,
.rightBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 400px;
}

.leftBox {
  width: 40%;
}

.rightBox {
  width: 60%;
}

.left_heading {
  margin-bottom: 20px !important;
  font-size: 2rem;
  line-height: 28px;
  font-weight: 600;
  text-align: center;
}

.rest_man {
  height: 400px;
}

.rightBox .heading {
  font-size: 2rem;
  line-height: 28px;
  font-weight: 600;
}

.rightBox video {
  border-radius: 16px;
  width: 430px;
  margin-top: 84px;
}

footer
.modal_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.leftFooter,
.rightFooter {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.leftFooter {
  width: 40%;
}

.rightFooter {
  width: 60%;
}

.exercise_text {
  font-size: 2rem;
  line-height: 28px;
  font-weight: 600;
}

.next_exercise_button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
}

.rest_previous > span {
  margin-left: 10px;
}

.btn__style {
  margin: 0;
  color: white;
  font-size: 2rem;
  line-height: 28px;
  font-weight: 600;
}

@media (max-width: 820px) {
  .main {
    margin-top: 26px;
    padding: 0 10px;
  }
  .rest_man {
    height: 360px;
  }
}

@media (max-width: 700px) {
  .main {
    flex-direction: column;
  }
  .leftBox,
  .rightBox {
    width: 100% !important;
    height: 250px;
  }
  .rightBox {
    margin-top: 16px;
  }
  .rightBox video {
    border-radius: 20px;
    width: 270px;
    margin-top: 12px;
  }

  .rest_man {
    height: 200px;
  }
  .exercise_text {
    font-size: 1.3rem;
    line-height: 20px;
    font-weight: 600;
  }
} */
/* model  */
.modal {
  width: 1140px !important;
  max-height: 700px;
}

.ant-modal-content {
  padding: 0;
}

.width {
  width: 100%;
}

/* main-content   */
.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
}

.main_div_top {
  padding: 30px 30px 0 30px;
}

.main_div_top > h3 {
  display: flex;
  align-items: center;
}

.main_without_video {
  height: 510px;
  margin-top: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftBox,
.rightBox {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.leftBox {
  width: 40%;
}

/* .rightBox {
  width: 60%;
} */

.rightBox {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.left_heading {
  margin-bottom: 20px !important;
  font-size: 2rem;
  line-height: 28px;
  font-weight: 600;
  text-align: center;
}

.rest_man {
  height: 400px;
}

.rightBox .heading {
  font-size: 2rem;
  line-height: 28px;
  font-weight: 600;
}

.rightBox video {
  border-radius: 16px;
  width: 430px;
  margin-top: 84px;
}

/*  footer */
.modal_footer {
  align-items: center;
  width: 100%;
  display: flex;
  padding: 30px;
  position: absolute;
  bottom: 0px;
  gap: 332px;
  justify-content: center;
}

.leftFooter,
.rightFooter {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
}

.leftFooter > p,
.rightFooter > p {
  width: 100%;
  display: flex;
  gap: 8px;
}

.leftFooter > p {
  justify-content: end;
}

.leftFooter > button > span {
  justify-content: end;
}

.exercise_text {
  font-weight: 600;
  font-size: 2rem;
  line-height: 28px;
  margin: 0;
  display: flex;
  align-items: center;
}

.next_exercise_button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
}

.rest_previous > span {
  margin-left: 10px;
}

.btn__style {
  margin: 0;
  color: white;
  font-size: 2rem;
  line-height: 28px;
  font-weight: 600;
}

.maincontent {
  margin-top: 11px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.contentleft {
  display: flex;
  flex-direction: column;
}

.exercise_textbottom {
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 34px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.smalltext {
  margin: 0;
  font-size: 2rem;
  line-height: 28px;
  align-items: center;
  font-weight: 600;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  display: none;
  -webkit-box-orient: vertical;
  color: #2b2923 !important;
}

.next_prev_timer {
  margin: 0;
  font-weight: 800;
  font-size: 2.4rem;
  line-height: 34px;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.leftFooter > button > .smalltext {
  justify-content: end;
}

@media (max-width: 1020px) {
  .rightBox video {
    width: 370px;
  }
  .leftFooter,
  .rightFooter {
    max-width: 215px;
  }
}

@media (max-width: 920px) {
  .rightBox video {
    width: 290px;
  }
}

@media (max-width: 820px) {
  .main {
    margin-top: 26px;
    padding: 0 10px;
  }
  .rest_man {
    height: 360px;
  }
}

@media (max-width: 700px) {
  .maincontent {
    grid-template-columns: 1fr;
  }
  .main {
    flex-direction: column;
  }
  .leftBox,
  .rightBox {
    width: 100% !important;
  }
  .rightBox {
    margin-top: 16px;
  }
  .rightBox video {
    border-radius: 20px;
    width: 270px;
    margin-top: 12px;
  }

  .rest_man {
    height: 200px;
  }
  .exercise_text {
    font-size: 1.3rem;
    line-height: 20px;
    font-weight: 600;
  }
  .exercise_textbottom {
    font-size: 1.3rem;
    line-height: 20px;
    font-weight: 600;
  }
}

@media (max-width: 837px) {
  .modal_footer {
    gap: 232px;
  }
  .exercise_textbottom {
    display: none;
  }
  .smalltext {
    font-size: 1.6rem;
    line-height: 24px;
    display: flex;
  }
  .exercise_text {
    display: none;
  }
  .next_prev_timer {
    font-size: 2rem;
    line-height: 30px;
  }
}

@media (max-width: 550px) {
  .smalltext {
    font-size: 1.2rem;
    line-height: 16px;
  }
  .modal_footer {
    padding: 16px;
    gap: 172px;
  }
  .next_prev_timer {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 20px;
  }
}

@media (max-width: 392px) {
  .modal_footer {
    gap: 104px;
    padding: 10px;
    overflow: hidden;
  }
  .smalltext > svg {
    width: 16px;
    height: 16px;
  }
}
