.list_wrapper {
  margin: 30px 0;
  width: 100%;
}
.list_content_wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 30px;
}
@media only screen and (max-width: 1300px) {
  .list_content_wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 950px) {
  .list_content_wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 650px) {
  .list_content_wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
