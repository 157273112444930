.feed_wrapper {
  margin: 30px 0;
  width: 100%;
}

.feed_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px !important;
}

.feed_header_title {
  font-weight: 600;
}

.feed_header_see_all_btn {
  color: #c69e03 !important;
  cursor: pointer;
  font-size: 1.8rem;
  line-height: 26px;
  padding: 0;
  display: flex;
}

.feed_content {
  /* display: grid;
  grid-template-columns: repeat(4, 1fr); */
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  overflow-x: scroll;
  width: 100%;
}
.feed_content::-webkit-scrollbar {
  width: 0;
  display: none;
}
