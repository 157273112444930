/* All the common class use in this App will be here */
p,
h2 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.border-radius-5 {
  border-radius: 5px !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}

.text_next_previous > span {
  margin-left: 10px !important;
}

@media (max-width: 468px) {
  .text_next_previous {
    font-size: 1.2rem;
  }
}

.m-0 {
  margin: 0;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100% !important;
}

.align-center {
  align-items: center !important;
}

.g-4 {
  gap: 16px;
}

.g-2 {
  gap: 8px;
}

.ml-1 {
  margin-left: 4px;
}

.flex-col {
  flex-direction: column;
}

.button-logout {
  color: #fbc90b;
}

.button-logout:hover {
  color: #fbc90b !important;
}

.no-data-height {
  height: calc(100vh - 90px);
}

.gap-1 {
  gap: 4px;
}

.d-none {
  display: none !important;
}

.blur1 {
  top: 0;
  width: 100vw;
  height: 100%;
  overflow: hidden !important;
  display: block;
  position: fixed;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
}

.loader-lottie {
  display: flex;
  width: 300px;
  height: 300px;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100% !important;
}

.pos-relative {
  position: relative;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
}
