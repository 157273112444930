.header_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.header_icon {
  width: 50px;
  height: 50px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: white;
  box-shadow: 1px 0px 50px rgba(205, 202, 194, 0.25);
  border-radius: 50% !important;
  cursor: pointer;
  color: black;
  padding: 0;
}

.header_title {
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
}
