/* .modal {
  width: 1140px !important;
  max-height: 700px;
}

.main_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.bottom_heading {
  font-size: 24px;
  line-height: 34px;
  color: #100c00;
  font-weight: 700;
  margin-bottom: 300px;
  text-align: center;
}

.button_div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  text-align: center;
  color: black;
  background-color: #fff;
  border-radius: 8px !important;
  margin-top: 24px;
}

@media (max-width: 800px) {
  .modal {
    width: 700px !important;
    max-height: 700px;
  }
} */

.modal {
  width: 1140px !important;
  max-height: 700px;
}

.main_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.bottom_heading {
  font-size: 24px;
  line-height: 34px;
  color: #100c00;
  font-weight: 700;
  margin-bottom: 300px;
  text-align: center;
}

.button_div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  text-align: center;
  color: black;
  background-color: #fff;
  border-radius: 8px !important;
  margin-top: 24px;
}

@media (max-width: 800px) {
  .modal {
    width: 700px !important;
    max-height: 700px;
  }
}

@media (max-width: 768px) {
  .bottom_heading {
    font-size: 20px;
    line-height: 28px;
  }
  .main_div > img {
    width: 225px;
    height: 330px;
  }
  .main_div {
    padding: 16px;
  }
}
