.maindiv {
  display: grid;
  grid-template-columns: auto auto;
  gap: 24px;
}

.heading {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #0b0a09;
  margin-bottom: 16px !important;
}

.innerdiv {
  width: 100%;
}

.divweeklyworkout {
  border: 1px solid rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

.divlist {
  padding: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.imgthumb {
  width: 120px;
  height: 64px;
}

.workoutnumber {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #8c8673;
}

.workoutname {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #0b0a09;
}

@media (max-width: 900px) {
  .maindiv {
    grid-template-columns: auto;
  }
}

@media (max-width: 500px) {
  .workoutnumber {
    font-size: 15px;
    line-height: 20px;
  }

  .workoutname {
    font-size: 16px;
    line-height: 24px;
  }
}
