.login_page_wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
}

/* img */
.login_page_img_wrapper {
  margin: 30px;
  width: 60%;
  max-width: 750px;
  background-color: #fffbec;
}
.login_page_img_wrapper img {
  width: 100%;
  height: 100%;
}

/* form */
.login_form_wrapper {
  width: 500px;
  max-width: 90%;
  margin: auto;
  padding: 30px;
}
.login_title {
  font-weight: 600;
  text-align: center;
  color: #6e5400;
  margin-bottom: 0;
  font-size: 4.4rem;
}
.login_description {
  text-align: center;
  color: #8c8673;
  margin-bottom: 20px !important;
  font-size: 1.8rem;
}

.login_btn {
  width: 100%;
  margin-top: 15px;
  border-radius: 5px !important;
  color: #0a0a09;
}

.login_btn:hover {
  color: #0a0a09 !important;
}

@media only screen and (max-width: 1000px) {
  .login_page_img_wrapper {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .login_form_wrapper {
    padding: 10px;
  }
  .login_title {
    font-size: 3.4rem;
  }
  .login_description {
    font-size: 1.4rem;
  }
}
